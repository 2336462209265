export const menuLists = [
  {
    title: "linkColumnOneTitle",
    links: [
      {
        linkName: "howTierWorksLink",
        link: "/how-tier-works",
        internal: true,
      },
      {
        linkName: "sustainabilityLink",
        link: "/sustainability/",
        force: true,
      },
      {
        linkName: "whereToFindUsLink",
        link: "/where-to-find-us",
        internal: true,
      },
      {
        linkName: "careerLink",
        link: "https://about.tier.app/jobs",
        force: true,
      },
    ],
  },
  {
    title: "linkColumnTwoTitle",
    links: [
      // {
      //   linkName: "tierForBusinessLink",
      //   link: "/business",
      //   internal: true,
      // },
      {
        linkName: "tierEnergyLink",
        link: "https://about.tier.app/swapspot",
        force: true,
      },
      {
        linkName: "myTierLink",
        link: "https://mytier.app",
        force: true,
      },
    ],
  },
  {
    title: "linkColumnFourTitle",
    links: [
      {
        linkName: "pressLink",
        link: "https://about.tier.app/press",
        force: true,
      },
      {
        linkName: "blogLink",
        link: "https://about.tier.app/blog",
        force: true,
      },
      {
        link: "https://tier.engineering/",
        linkName: "Engineering",
        force: true,
      },
    ],
  },
  {
    title: "linkColumnThreeTitle",
    links: [
      {
        linkName: "helpLink",
        link: "https://about.tier.app/help",
        icon: "help",
        force: true,
      },
      {
        linkName: "contactLink",
        link: "/contact",
        icon: "contact",
        internal: true,
      },
    ],
  },
]

export const languages = [
  {
    flag: "sa",
    language: "Arabic",
    code: "ar",
  },
  {
    flag: "es",
    language: "Catalan",
    code: "ca",
  },
  {
    flag: "dk",
    language: "Danish",
    code: "da",
  },
  {
    flag: "nl",
    language: "Dutch",
    code: "nl",
  },
  {
    flag: "gb",
    language: "English",
    code: "en",
  },
  {
    flag: "fi",
    language: "Finnish",
    code: "fi",
  },
  {
    flag: "fr",
    language: "French",
    code: "fr",
  },
  {
    flag: "de",
    language: "German",
    code: "de",
  },
  {
    flag: "il",
    language: "Hebrew",
    code: "he",
  },
  {
    flag: "hu",
    language: "Hungarian",
    code: "hu",
  },
  {
    flag: "it",
    language: "Italian",
    code: "it",
  },
  {
    flag: "no",
    language: "Norwegian",
    code: "nb",
  },
  {
    flag: "pl",
    language: "Polish",
    code: "pl",
  },
  {
    flag: "pt",
    language: "Portuguese",
    code: "pt",
  },
  {
    flag: "sk",
    language: "Slovak",
    code: "sk",
  },
  {
    flag: "es",
    language: "Spanish",
    code: "es",
  },
  {
    flag: "se",
    language: "Swedish",
    code: "sv",
  },
  {
    flag: "ro",
    language: "Romanian",
    code: "ro",
  },
]
