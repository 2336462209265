import React, { useEffect, useState, useCallback, useRef } from "react"
import PropTypes from "prop-types"
import gsap from "gsap"
import CustomEase from "../../utils/CustomEase"

// Components
import ChevronDown from "../../components/icons/ChevronDown"
import LinkChoice from "../../components/LinkChoice"
import ExternalLinkSmall from "../../components/icons/ExternalLinkSmall"

gsap.registerPlugin(CustomEase)

const AccordionItem = ({ title, links, selectedItem, index, toggleContent, close, ...props }) => {
  const { className, ...addonProps } = props
  const [active, setActive] = useState(false)
  const contentRef = useRef()
  const headerRef = useRef()

  /**
   * Methods
   */
  const toggleItem = () => {
    const newValue = selectedItem === index ? null : index
    setActive(newValue === index)
    toggleContent(newValue)
  }

  const onKeyUpAction = e => {
    if (e.key === "Enter" || e.key === "Space") toggleItem()
  }
  const onKeyDownAction = e => {
    if (e.key === "Escape") {
      toggleContent(null)
      setActive(false)
    }
  }

  const animate = useCallback(() => {
    const height = active ? "auto" : 0
    const opacity = active ? 1 : 0
    CustomEase.create("accordion", "0.45,0.05,0.55,0.95")
    gsap.to(contentRef.current, { height, opacity, duration: 0.3, ease: "accordion" })
  }, [active, contentRef])

  /** Run on component mount */
  useEffect(() => {
    if (active) animate()
  }, [])

  /** Run whenever the item is updated */
  useEffect(() => {
    animate()
  }, [active, animate])

  /** Close item if other accordeon item was selected */
  useEffect(() => {
    if (active && selectedItem !== index) setActive(false)
  }, [selectedItem])

  /**
   * Component
   */
  return (
    <li className={className} {...addonProps}>
      <div
        ref={headerRef}
        className="font-extrabold text-blue text-h6 cursor-pointer flex items-center py-5 md:py-8 border-b border-gray-300 border-opacity-50 focus:outline-none transition"
        role="button"
        tabIndex="0"
        aria-haspopup="listbox"
        aria-expanded={active ? "true" : "false"}
        onClick={toggleItem}
        onKeyUp={onKeyUpAction}
        onKeyDown={onKeyDownAction}
      >
        <span className="font-extended flex-grow">{title}</span>
        <ChevronDown color="#0E1A50" className={(active ? "rotate-180" : "rotate-0") + " transform transition-transform"} />
      </div>
      <div ref={contentRef} className="h-0 opacity-0 overflow-hidden" role="listbox" onClick={close ? () => close() : null}>
        <div className="px-4 pt-5">
          {links.map(({ linkAddress }, index) => {
            const link = linkAddress[0]
            return (
              <LinkChoice key={link.text + index} link={link} to={link.address} className="flex text-gray-600 items-center mb-5 lg:mb-12">
                <p className="text-p2 lg:text-n1 lg:font-bold">{link.text}</p>
                {link.actionType === "external" && !link.address.includes("about.tier.app") && <ExternalLinkSmall />}
              </LinkChoice>
            )
          })}
        </div>
      </div>
    </li>
  )
}

AccordionItem.propTypes = {
  /**
   * Item header's text
   */
  title: PropTypes.string.isRequired,
  /**
   * Item list of links
   */
  links: PropTypes.array.isRequired,
  /**
   * The currently selected item of the accordeon
   */
  selectedItem: PropTypes.number,
  /**
   * This item's index number inside the accordeon
   */
  index: PropTypes.number.isRequired,
  /**
   * Function to update the accordeon state
   */
  toggleContent: PropTypes.func.isRequired,
  close: PropTypes.func,
}

AccordionItem.defaultProps = {
  selectedItem: null,
}

export default AccordionItem
