import React, { useEffect, useState, useCallback, useRef } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import gsap from "gsap"
import { Link } from "gatsby"
import { isHTML } from "../../utils/helpers"

import CustomEase from "../../utils/CustomEase"

gsap.registerPlugin(CustomEase)

// Components
import PlusIcon from "../../components/icons/PlusIcon"

const ItemWrapper = styled.div`
  & > p {
    margin-bottom: 40px;
  }

  & a {
    color: #0050ff;
  }
`

const AccordionItem = ({ header, body, selectedItem, index, link, toggleContent, ...props }) => {
  const { className, ...addonProps } = props
  const [active, setActive] = useState(false)
  const contentRef = useRef()
  const headerRef = useRef()

  /**
   * Methods
   */
  const toggleItem = () => {
    const newValue = selectedItem === index ? null : index
    setActive(newValue === index)
    toggleContent(newValue)
  }

  const onKeyUpAction = e => {
    if (e.key === "Enter" || e.key === "Space") toggleItem()
  }
  const onKeyDownAction = e => {
    if (e.key === "Escape") {
      toggleContent(null)
      setActive(false)
    }
  }

  const animate = useCallback(() => {
    const height = active ? "auto" : 0
    const opacity = active ? 1 : 0
    CustomEase.create("accordion", "0.45,0.05,0.55,0.95")
    gsap.to(contentRef.current, { height, opacity, duration: 0.3, ease: "accordion" })
  }, [active, contentRef])

  /** Run on component mount */
  useEffect(() => {
    if (active) animate()
  }, [])

  /** Run whenever the item is updated */
  useEffect(() => {
    animate()
  }, [active, animate])

  /** Close item if other accordeon item was selected */
  useEffect(() => {
    if (active && selectedItem !== index) setActive(false)
  }, [selectedItem])

  /**
   * Component
   */
  return (
    <li className={"border-b border-gray-200 mx-auto " + className} {...addonProps} style={{ maxWidth: 816 }}>
      <div
        ref={headerRef}
        className="cursor-pointer flex items-center justify-between py-8 lg:py-10 group focus:outline-none"
        role="button"
        tabIndex="0"
        aria-haspopup="listbox"
        aria-expanded={active ? "true" : "false"}
        onClick={toggleItem}
        onKeyUp={onKeyUpAction}
        onKeyDown={onKeyDownAction}
      >
        <h6 className="font-extended font-extrabold text-blue">{header}</h6>
        <div className="ml-4">
          <PlusIcon width={24} height={24} className={"text-orange-highlight transition" + (active ? " transform rotate-45" : "")} />
        </div>
      </div>
      {isHTML(body) ? (
        <ItemWrapper
          ref={contentRef}
          className="h-0 opacity-0 overflow-hidden ext-p2 text-blue text-justify max-w-2xl"
          role="listbox"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      ) : (
        <div ref={contentRef} className="h-0 opacity-0 overflow-hidden" role="listbox">
          <p className="text-p2 text-blue text-justify mb-10 max-w-2xl">{body}</p>
          {link && (
            <Link to={link.link}>
              <p className="text-p2 text-blue-informative underline text-justify mb-10 max-w-2xl">{link.name}</p>
            </Link>
          )}
        </div>
      )}
    </li>
  )
}

AccordionItem.propTypes = {
  /**
   * Item header's text
   */
  header: PropTypes.string.isRequired,
  /**
   * Item body's text
   */
  body: PropTypes.string.isRequired,
  /**
   * Item icon
   */
  selectedItem: PropTypes.number,
  /**
   * This item's index number inside the accordeon
   */
  index: PropTypes.number.isRequired,
  /**
   * Function to update the accordeon state
   */
  toggleContent: PropTypes.func.isRequired,
  link: PropTypes.object,
}

AccordionItem.defaultProps = {
  selectedItem: null,
}

export default AccordionItem
