import React, { useState, createContext, useEffect } from "react"
import PropTypes from "prop-types"

// Create Context Object
export const BodyLockContext = createContext()

// Create a provider for components to consume and subscribe to changes
export const BodyLockContextProvider = ({ ...props }) => {
  const [bodyLock, setBodyLock] = useState(false)

  useEffect(() => {
    if (bodyLock) {
      while (document.body.style.overflow !== "hidden") {
        document.body.style.overflow = "hidden"
      }
    } else {
      while (document.body.style.overflow === "hidden") {
        document.body.style.overflow = "auto"
      }
    }
  }, [bodyLock])

  return <BodyLockContext.Provider value={[bodyLock, setBodyLock]}>{props.children}</BodyLockContext.Provider>
}

BodyLockContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
