import React from "react"
import PropTypes from "prop-types"

function Facebook({ color, width, height, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        className="fill-current"
        fill={color}
        d="M18.5 8.463c-.488 0-.875.087-1.163.25-.287.162-.474.362-.55.612-.087.25-.124.563-.124.938v2.575h3.462l-.488 3.7h-2.974v9.337h-3.838v-9.337H9.688v-3.713h3.137V9.887c0-1.55.438-2.75 1.3-3.612C15 5.425 16.163 5 17.6 5c1.175 0 2.125.05 2.85.162v3.3H18.5z"
      ></path>
    </svg>
  );
}

Facebook.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Facebook.defaultProps = {
  color: "#0E1A50",
  width: 30,
  height: 30,
}

export default Facebook;
