import { navigate } from "gatsby"
import * as scrollToElement from "scroll-to-element"

export const parseHeadline = string => {
  let divided = string.split("\n")

  return divided
}

export const setEventListener = (event, action, remove) => {
  if (remove) {
    window.removeEventListener(event, action)
    return
  }

  window.addEventListener(event, action)
}

export const fireEvent = (eventName, data) => {
  const event = new CustomEvent(eventName, { detail: data })
  window.dispatchEvent(event)
}
export function classes(dynamic, classes = "") {
  return Object.entries(dynamic)
    .filter(entry => entry[1])
    .map(entry => entry[0])
    .join(" ")
    .concat(" ")
    .concat(classes)
}

export const setGlobalVH = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`)
  document.documentElement.style.setProperty("--variable-vh", `${vh}px`)
}

export const updateGlobalVH = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty("--variable-vh", `${vh}px`)
}

export const handleScrollToElement = (id, duration, callback) => {
  if (!duration) {
    scrollToElement(id, { ease: "in-out-sine" })
  } else {
    scrollToElement(id, {
      ease: "in-out-sine",
      duration: duration,
    })

    if (callback) setTimeout(callback, duration)
  }
}

export const parseLink = link => {
  if (!link) return ""
  if (link.includes("https://")) {
    return link.split("https://")[1]
  } else if (link.includes("http://")) {
    return link.split("http://")[1]
  } else return link
}

export const goTo = (address, actionType, openInNewWindow) => {
  console.log(actionType === "internal" ? address : window.location.origin + address, address, actionType, openInNewWindow)
  if (actionType === "anchor") {
    handleScrollToElement(parseLink(address), 1200)
    return
  }

  if (actionType === "internal") {
    navigate(parseLink(address))
  } else {
    window.location.href = address
  }
}

export const countryList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua &amp; Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia &amp; Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote D Ivoire",
  "Croatia",
  "Cruise Ship",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Polynesia",
  "French West Indies",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kuwait",
  "Kyrgyz Republic",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Pierre &amp; Miquelon",
  "Samoa",
  "San Marino",
  "Satellite",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "St Kitts &amp; Nevis",
  "St Lucia",
  "St Vincent",
  "St. Lucia",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor L'Este",
  "Togo",
  "Tonga",
  "Trinidad &amp; Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks &amp; Caicos",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "Uruguay",
  "Uzbekistan",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (US)",
  "Yemen",
  "Zambia",
  "Zimbabwe",
]

export const parseRightMedia = (media, mobileMedia, width) => {
  if (width <= 640 && mobileMedia.length) {
    return mobileMedia
  } else return media
}

export function getLanguageFromURL(search) {
  let hashes = search.slice(search.indexOf("?") + 1).split("&")
  let params = {}
  hashes.map(hash => {
    let [key, val] = hash.split("=")
    params[key] = decodeURIComponent(val)
  })

  return params.lang === "no" ? "nor" : params.lang || "en"
}

export const getRedirectLanguage = () => {
  if (localStorage.getItem("lang")) {
    console.log("Returning language based on local storage")
    return localStorage.getItem("lang")
  }

  if (typeof navigator === `undefined`) {
    console.log("Returning language english because navigator undefined")
    return "en"
  }

  const lang = navigator && navigator.language && navigator.language.split("-")[0]
  if (!lang) return "en"

  console.log("Returning language based on navigator, ", lang)

  switch (lang) {
    case "en":
      return "en"
    case "es":
      return "es"
    default:
      return "en"
  }
}

export function compareAlphabetical(a, b) {
  var textA = a.title.toLowerCase()
  var textB = b.title.toLowerCase()
  return textA < textB ? -1 : textA > textB ? 1 : 0
}

export function compareAlphabeticalString(a, b) {
  var textA = a.toLowerCase()
  var textB = b.toLowerCase()
  return textA < textB ? -1 : textA > textB ? 1 : 0
}

export function isHTML(string) {
  return /<\/?[a-z][\s\S]*>/i.test(string)
}

export const siteLanguageDictionary = {
  1: "en",
  3: "es",
  4: "fi",
  5: "ar",
  6: "ca",
  7: "da",
  8: "nl",
  9: "fr",
  10: "de",
  11: "hu",
  12: "it",
  13: "nb",
  14: "pl",
  15: "sk",
  16: "sv",
  17: "he",
  18: "pt",
  en: 1,
  es: 3,
  fi: 4,
  ar: 5,
  ca: 6,
  da: 7,
  nl: 8,
  fr: 9,
  de: 10,
  hu: 11,
  it: 12,
  nb: 13,
  pl: 14,
  sk: 15,
  sv: 16,
  he: 17,
  pt: 18,
}

export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

export async function copyTextToClipboard(text) {
  if (!navigator) return

  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    return document.execCommand("copy", true, text)
  }
}

export function removeTags(str) {
  if (str === null || str === "") return false
  else str = str.toString()

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "")
}

export function getHeadlineSize(size) {
  const sizes = {
    d1: "md:text-d1 ",
    h1: "md:text-h1 ",
    h2: "md:text-h2 ",
    h3: "md:text-h3 ",
    h4: "md:text-h4 ",
    h5: "md:text-h5 ",
    h6: "md:text-h6 ",
    h7: "md:text-h7 ",
  }

  return size ? sizes[size] : sizes["h1"]
}

export function removeDuplicates(arr, key) {
  let lookup = new Map()
  let uniqueArr = []
  for (let i = 0; i < arr.length; i++) {
    let obj = arr[i]
    let val = obj[key]
    if (!lookup.has(val)) {
      lookup.set(val, true)
      uniqueArr.push(obj)
    }
  }
  return uniqueArr
}
