import React from "react"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import Logo from "../../components/icons/Logo"
import { SocialBar, ImprintBar } from "./FooterBars"
import BadgeAppStore from "../../components/icons/BadgeAppStore"
import BadgeGooglePlay from "../../components/icons/BadgeGooglePlay"
import ListBlock from "../../components/containers/ListBlock"
import LanguageSwitcher from "../../components/LanguageSwitcher"

const Footer = ({ content, button, languages }) => {
  const { pathname } = useLocation()

  return (
    <div className="flex flex-col px-6 pt-20 md:px-12 xl:px-20">
      {/* Logo block */}
      <div className="flex justify-between items-center pb-8 w-full max-w-screen-2xl m-auto">
        <Logo color="tertiary" />
        {pathname && !pathname.includes("/preview/") && <LanguageSwitcher languages={languages} footer />}
      </div>

      {/* Upper footer block */}
      <div className="flex flex-col md:grid md:grid-cols-3 lg:flex lg:flex-row text-blue py-12 justify-between lg:align-start space-y-12 md:space-y-0 md:gap-y-12 max-w-screen-2xl lg:mx-auto w-full">
        <div>
          <ListBlock title={content[0].linkList[0].header} list={content[0].linkList[0].links} />
        </div>

        <div>
          <ListBlock title={content[0].linkList[1].header} list={content[0].linkList[1].links} />
        </div>

        <div>
          <ListBlock title={content[0].linkList[2].header} list={content[0].linkList[2].links} />
        </div>

        <div>
          <ListBlock title={content[0].linkList[3].header} list={content[0].linkList[3].links} />
        </div>

        <div className="flex flex-row flex-wrap justify-between md:flex-col md:justify-start space-y-4 md:space-y-3 max-w-lg text-blue">
          <p className="font-extended w-full text-p3 font-extrabold mb-2 lg:mb-3">{button.getAppText}</p>
          <div
            className="border-2 border-blue rounded-md md:w-full hover:bg-opacity-20 hover:bg-blue transition-colors duration-150"
            style={{ width: 194 }}
          >
            <BadgeAppStore background={"blue"} color="#0E1A50" />
          </div>
          <div className="border-2 border-blue rounded-md md:w-max hover:bg-opacity-20 hover:bg-blue transition-colors duration-150">
            <BadgeGooglePlay background={"blue"} color="#0E1A50" />
          </div>
        </div>
      </div>

      {/* Lower footer block */}
      <div className="flex flex-col md:flex-row justify-between w-full border-t border-blue border-opacity-20 pb-12 pt-7 lg:pt-10 max-w-screen-2xl m-auto">
        <ImprintBar className="order-last md:order-first md:flex-row" content={content[0].linkList[4]} />
        <SocialBar className="text-blue md:gap-x-6" content={content[0].linkList[5]} />
        {/* Divider for mobile */}
        <div className="block md:hidden border-b border-blue opacity-20 pt-7 mb-12" />
      </div>
    </div>
  )
}

Footer.propTypes = {
  content: PropTypes.array,
  languages: PropTypes.array,
  button: PropTypes.object,
  id: PropTypes.string,
}

export default Footer
