import React from "react"
import PropTypes from "prop-types"

function LinkedIn({ color, width, height, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        className="fill-current"
        fill={color}
        d="M23.025 23.025h-3.487V17.55c0-.325 0-.588-.013-.8a5.359 5.359 0 00-.112-.762c-.063-.3-.15-.538-.263-.713-.112-.175-.287-.325-.525-.463-.238-.137-.525-.2-.875-.2-.825 0-1.375.263-1.663.788-.3.512-.437 1.213-.437 2.075v5.537h-3.488V11.825h3.375v1.538h.038c.25-.5.663-.925 1.238-1.288.575-.362 1.262-.537 2.062-.537.85 0 1.55.124 2.113.387.562.262.987.65 1.274 1.162.288.513.488 1.063.6 1.663.113.6.163 1.313.163 2.137v6.138zM9.7 9.7c-.4.4-.875.6-1.425.6s-1.025-.213-1.425-.6c-.4-.387-.6-.875-.6-1.425s.2-1.025.6-1.425c.4-.4.875-.6 1.425-.6s1.025.2 1.425.6c.4.4.6.875.6 1.425S10.087 9.3 9.7 9.7zm.288 13.325H6.513v-11.2H10v11.2h-.012z"
      ></path>
    </svg>
  );
}

LinkedIn.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

LinkedIn.defaultProps = {
  color: "#0E1A50",
  width: 30,
  height: 30,
}

export default LinkedIn;
