import React from "react"

function ExternalLinkSmall() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
      <path
        stroke="#4A505E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M5.715 11.254l5.537-5.537M11.252 9.182V5.717H7.787"
      ></path>
    </svg>
  )
}

export default ExternalLinkSmall
