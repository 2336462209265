// Dependencies
import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"

// Components
import NavBar from "../../modules/NavBar/NavBar"
import Menu from "../../modules/Menu/Menu"
import Footer from "../../modules/Footer/Footer"

// Helpers
import { BodyLockContextProvider } from "../contexts/BodyLockContext"
import { LocaleContextProvider } from "../contexts/LocaleContext"
import { getRedirectLanguage, setGlobalVH, updateGlobalVH } from "../../utils/helpers"
import { graphql, useStaticQuery } from "gatsby"
import { layoutPlaceholder } from "../../utils/layoutPlaceholder"
import { isSpinURL } from "../../utils/spinCodes"

const Layout = ({ children }) => {
  const location = useLocation()
  const [layoutInfo, setLayoutInfo] = useState(layoutPlaceholder)
  const { button, menuContent, footerContent, languages } = layoutInfo
  const [menuOpen, setMenuOpen] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)

  const toggleMenu = force => {
    if (force !== undefined && force !== null && typeof force !== "object") {
      setMenuOpen(force)
      setIsAnimating(true)
      return
    }

    if (!isAnimating) setMenuOpen(!menuOpen)

    setIsAnimating(true)
  }

  const { menu, footer, getAppButton, languageSwitcher } = useStaticQuery(graphql`
    query MenuQuery {
      languageSwitcher: craft {
        globalSets(handle: "languageSwitcher") {
          ... on craft_languageSwitcher_GlobalSet {
            languageSwitcher {
              ... on craft_languageSwitcher_language_BlockType {
                languageName
                code
              }
            }
          }
        }
      }
      getAppButton: craft {
        globalSets(site: "*", handle: "getAppButton") {
          language
          ... on craft_getAppButton_GlobalSet {
            getAppText
            appStoreLink
            googlePlayLink
          }
        }
      }
      menu: craft {
        globalSets(site: "*", handle: "menuContent") {
          language
          ... on craft_menuContent_GlobalSet {
            linkList {
              ... on craft_linkList_list_BlockType {
                header
                links {
                  ... on craft_links_default_Entry {
                    id
                    linkAddress {
                      ... on craft_linkAddress_block_BlockType {
                        actionType
                        address
                        openInNewWindow
                        text
                        icon {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      footer: craft {
        globalSets(site: "*", handle: "footerContent") {
          language
          ... on craft_footerContent_GlobalSet {
            linkList {
              ... on craft_linkList_list_BlockType {
                header
                links {
                  ... on craft_links_default_Entry {
                    id
                    linkAddress {
                      ... on craft_linkAddress_block_BlockType {
                        actionType
                        address
                        openInNewWindow
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const checkLocaleChange = event => {
    const lang = event.detail

    setLayoutInfo({
      lang,
      button: getAppButton.globalSets.filter(e => e.language === lang)[0],
      menuContent: menu.globalSets.filter(e => e.language === lang),
      footerContent: footer.globalSets.filter(e => e.language === lang),
      languages: languageSwitcher.globalSets[0].languageSwitcher,
    })
  }

  useEffect(() => {
    let code = location.pathname.split("/")[1]

    // console.log("code testing from Layout")

    if (isSpinURL(code)) {
      window.location.href = "https://scooters.spin.pm/qr/" + code
      return
    }

    if (code && /^([A-Z]{2}[0-9]+)$/.test(code)) {
      window.location.href = "https://qr.tier-services.io/" + code
      return
    }

    const locale = getRedirectLanguage()

    setLayoutInfo({
      locale,
      button: getAppButton.globalSets.filter(e => e.language === locale)[0],
      menuContent: menu.globalSets.filter(e => e.language === locale),
      footerContent: footer.globalSets.filter(e => e.language === locale),
      languages: languageSwitcher.globalSets[0].languageSwitcher,
    })

    window.addEventListener("locale-change", checkLocaleChange)

    return () => window.removeEventListener("locale-change", checkLocaleChange)
  }, [location])

  useEffect(() => {
    // Time animation offset
    if (isAnimating) {
      setTimeout(() => setIsAnimating(false), 1000)
    }
  }, [isAnimating])

  useEffect(() => {
    setGlobalVH()
    window.addEventListener("resize", updateGlobalVH)

    return () => {
      window.removeEventListener("resize", updateGlobalVH)
    }
  }, [])

  return (
    <LocaleContextProvider>
      <BodyLockContextProvider>
        <main className="bg-background">
          <NavBar button={button} toggleMenu={toggleMenu} isOpen={menuOpen} isAnimating={isAnimating} languages={languages} />
          <Menu menuContent={menuContent} button={button} isOpen={menuOpen} isAnimating={isAnimating} toggleMenu={toggleMenu} />
          {children}
          <Footer content={footerContent} button={button} languages={languages} />
        </main>
      </BodyLockContextProvider>
    </LocaleContextProvider>
  )
}

export default Layout
