import React from "react"
import PropTypes from "prop-types"

function ChevronDown({ color, width, height, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0)">
        <path
          className="fill-current"
          fill={color}
          d="M8 12a.997.997 0 01-.651-.241l-7.76-6.65L.893 3.59 8 9.683l7.108-6.093 1.302 1.519-7.76 6.65A.997.997 0 018 12z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path className="fill-current" fill={color} d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

ChevronDown.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

ChevronDown.defaultProps = {
  color: "#FFFFFF",
  width: 16,
  height: 16,
}

export default ChevronDown;
