import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { handleScrollToElement, parseLink } from "../utils/helpers"
import { LocaleContext } from "./contexts/LocaleContext"

const LinkChoice = ({ link, children, ...props }) => {
  const { to, ...otherProps } = props
  const [locale] = useContext(LocaleContext)

  if (link.actionType === "internal") {
    return (
      <Link to={"/" + locale + parseLink(to)} {...otherProps}>
        {children}
      </Link>
    )
  } else if (link.actionType === "anchor") {
    return (
      <a
        onClick={() => {
          console.log("Anchor", parseLink(to))
          handleScrollToElement(parseLink(to), 800)
        }}
      >
        {children}
      </a>
    )
  } else {
    return (
      <a href={to.includes("mailto") ? parseLink(to) : to} target={link.openInNewWindow ? "_blank" : "_self"} rel="noreferrer" {...otherProps}>
        {children}
      </a>
    )
  }
}

LinkChoice.propTypes = {
  link: PropTypes.object.isRequired,
  to: PropTypes.string,
}

export default LinkChoice
