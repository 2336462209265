import { useState, useEffect } from 'react';

export function useIsMacOS() {
  const [macOS, setMacOS] = useState(false)

  useEffect(() => {
    let isAppleDevice = window.navigator.userAgent.includes("Mac")
    setMacOS(isAppleDevice)
  }, []);

  return macOS;
}