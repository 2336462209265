import React from "react"
import PropTypes from "prop-types"

// Components
import RevealText from "../../components/RevealText"
import LinkChoice from "../../components/LinkChoice"

// Helpers
import { useWindowSize } from "../../components/hooks/useWindowSize"
import underline from "../../assets/images/underline.png"
import { useReverseLanguage } from "../../components/hooks/useReverseLanguage"
import ExternalLink from "../../components/icons/ExternalLink"

const MenuLinkList = ({ links, title, titleRef, listRef, ...props }) => {
  const { height } = useWindowSize()

  return (
    <div {...props} className={"flex flex-col " + (height > 1000 ? "mb-28" : "")}>
      <p className="text-gray-400 mb-5 lg:mb-9">
        <RevealText forwardedRef={titleRef} text={title} />
      </p>
      <div ref={ref => listRef.push(ref)}>
        {links.map(({ linkAddress }) => {
          const link = linkAddress[0]
          return (
            <LinkChoice link={link} key={link.text} to={link.address} className="relative flex text-blue items-center mb-5 lg:mb-12">
              {link.icon[0] && <img alt="icon" src={link.icon[0].url} className={useReverseLanguage() ? "ml-3" : "mr-3"} />}
              <div className="group relative flex">
                <p className="font-extended relative z-10 text-p2 lg:text-n1 lg:font-extrabold text-blue">{link.text}</p>
                {link.actionType === "external" && !link.address.includes("about.tier.app") && <ExternalLink />}
                <img
                  alt="underline"
                  src={underline}
                  className="absolute z-0 -bottom-2 left-0 w-full h-4 opacity-0 group-hover:opacity-100 transition-opacity"
                />
              </div>
            </LinkChoice>
          )
        })}
      </div>
    </div>
  )
}

MenuLinkList.propTypes = {
  links: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  titleRef: PropTypes.array,
  listRef: PropTypes.array,
}

export default MenuLinkList
