import React from "react"
import PropTypes from "prop-types"

function Twitter({ color, width, height, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        className="fill-current"
        fill={color}
        d="M22.95 11.287c.025.1.038.25.038.475a10.65 10.65 0 01-.726 3.838 12.497 12.497 0 01-2.05 3.5c-.887 1.05-2.037 1.912-3.437 2.563-1.412.65-2.962.987-4.662.987-2.138 0-4.088-.575-5.863-1.712.263.024.575.037.912.037 1.776 0 3.363-.55 4.763-1.638-.85 0-1.6-.25-2.238-.75-.637-.5-1.087-1.125-1.324-1.875.237.026.475.038.687.038.337 0 .675-.038 1.025-.113a3.74 3.74 0 01-1.563-.725 4.077 4.077 0 01-1.1-1.337 3.689 3.689 0 01-.4-1.688v-.075c.538.313 1.1.488 1.713.513-1.137-.775-1.712-1.85-1.712-3.2 0-.675.187-1.325.55-1.925a10.62 10.62 0 003.474 2.825c1.375.7 2.838 1.088 4.388 1.162a5.025 5.025 0 01-.075-.862c0-1.037.375-1.938 1.112-2.688.738-.762 1.625-1.137 2.675-1.137 1.113 0 2.05.4 2.8 1.2a7.85 7.85 0 002.438-.912c-.288.9-.85 1.6-1.675 2.112.725-.1 1.45-.3 2.188-.613a8.41 8.41 0 01-1.938 2z"
      ></path>
    </svg>
  );
}

Twitter.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Twitter.defaultProps = {
  color: "#0E1A50",
  width: 30,
  height: 30,
}

export default Twitter;
