import { useState, useEffect, useContext } from "react"
import { useLocation } from "@reach/router"
import { LocaleContext } from "../contexts/LocaleContext"

export function useReverseLanguage() {
  const [locale] = useContext(LocaleContext)
  const { pathname } = useLocation()
  const [reverseLanguage, setReverseLanguage] = useState(false)

  useEffect(() => {
    // console.log(pathname, pathname.includes("israel"))
    let reverseLang = locale === "ar" || locale === "he" || pathname.includes("israel") || pathname.includes("saudi")
    setReverseLanguage(reverseLang)
  }, [locale])

  return reverseLanguage
}
