import React, { useContext, useRef, useState } from "react"
import PropTypes from "prop-types"
// import { flag } from "country-emoji"

import { useClickOutside } from "./hooks/useClickOutside"
import { useReverseLanguage } from "./hooks/useReverseLanguage"

import check from "../assets/images/check.svg"
import { LocaleContext } from "./contexts/LocaleContext"

const LanguageSwitcher = ({ className, toggleMenu, theme, languages }) => {
  const container = useRef(null)
  const [locale, setLocale] = useContext(LocaleContext)
  const [open, setOpen] = useState(false)

  useClickOutside(container, () => setOpen(false), open)

  return (
    <div ref={container} className={"relative " + className}>
      <button
        className={"relative w-12 h-12 rounded-lg border flex items-center justify-center " + (theme === "light" ? "border-white" : "border-blue")}
        onClick={() => setOpen(!open)}
      >
        <span className={"mb-1 " + (theme === "light" ? "text-white" : "text-blue")}>
          {languages.filter(e => e.code === locale)[0] ? languages.filter(e => e.code === locale)[0].code.toUpperCase() : null}
        </span>
      </button>
      <div
        className={
          "absolute w-48 h-auto bg-white rounded-lg px-6 py-2 z-40 shadow-nav overflow-y-auto top-16 transform origin-top " +
          (useReverseLanguage() ? "left-0 " : "right-0 ") +
          (open ? "scale-y-100" : "scale-y-0")
        }
        style={{ maxHeight: 385, transition: `transform 0.3s ${open ? 0 : 0.2}s cubic-bezier(0.45,0.05,0.55,0.95)` }}
      >
        {languages.map(lang => {
          return (
            <button
              key={lang.code}
              className={
                "relative flex py-2 gap-x-4 items-center h-11 w-full transition-opacity duration-300 " +
                (open ? "opacity-100 delay-300" : "opacity-0 delay-0")
              }
              onClick={() => {
                setLocale(lang.code)
                setOpen(false)
                toggleMenu()
              }}
            >
              {/* <p>{flag(lang.flag)}</p> */}
              <p className={"hover:text-blue-informative text-p3 " + (locale === lang.code ? "text-blue-informative" : "text-gray-500")}>
                {lang.languageName}
              </p>
              {locale === lang.code && <img alt="check" src={check} className={"absolute " + (useReverseLanguage() ? "left-0" : "right-0")} />}
            </button>
          )
        })}
      </div>
    </div>
  )
}

LanguageSwitcher.propTypes = {
  toggleMenu: PropTypes.func,
  theme: PropTypes.string,
  languages: PropTypes.array,
}

LanguageSwitcher.defaultProps = {
  toggleMenu: () => null,
  theme: "dark",
}
export default LanguageSwitcher
