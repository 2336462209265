import styled from "styled-components"

export const MenuContainer = styled.div`
  --right-position: calc(100% - 7rem);
  --top-position: 3.5rem;
  --color: #067A72;

  opacity: 0;
  transition: opacity 0s 1.5s;

  &.show {
    opacity: 1;

    transition: opacity 0s 0s;
  }

  & a > li {
    color: white;
  }

  @media (max-width: 768px) {
    --right-position: calc(100% - 2.55rem);
    --top-position: 2.5rem;
  }
`

export const backgroundStates = {
  initial:
    "radial-gradient(circle at var(--right-position) var(--top-position), transparent 100%, var(--color) 100.1%, var(--color) 100%, transparent 100.1%)",
  open: "radial-gradient(circle at var(--right-position) var(--top-position), transparent 1.8%, var(--color) 1.9%, var(--color) 100%, transparent 100.1%)",
  closed:
    "radial-gradient(circle at var(--right-position) var(--top-position), transparent 1.8%, var(--color) 1.9%, var(--color) 1.8%, transparent 1.9%)",
}
