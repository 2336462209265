import React, { useState, useEffect, createContext } from "react"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import { fireEvent } from "../../utils/helpers"

// Create Context Object
export const LocaleContext = createContext()

// Create a provider for components to consume and subscribe to changes
export const LocaleContextProvider = ({ ...props }) => {
  const { pathname } = useLocation()
  const [locale, setLocaleState] = useState("en")

  const setLocale = (lang, slug) => {
    setLocaleState(lang)
    let route = `/${lang}/`
    const currentPath = slug ? slug : pathname.split("/").filter(e => e)[1]

    localStorage.setItem("lang", lang)

    if (currentPath) route = route + currentPath

    navigate(route)
  }

  useEffect(() => {
    const lang = pathname.split("/").filter(e => e)[0]

    if (lang && lang.length === 2) {
      // console.log("Setting Locale Initally in Context ", lang)
      setLocaleState(lang)
      localStorage.setItem("lang", lang)
      fireEvent("locale-change", lang)
    }
  }, [])

  return <LocaleContext.Provider value={[locale, setLocale]}>{props.children}</LocaleContext.Provider>
}

LocaleContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
