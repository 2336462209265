import React from "react"

function ExternalLink() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none" viewBox="0 0 23 23">
      <path
        stroke="#0E1A50"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M7.624 15.005l7.382-7.382M15.005 12.242v-4.62h-4.62"
      ></path>
    </svg>
  )
}

export default ExternalLink
