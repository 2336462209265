export const layoutPlaceholder = {
  locale: "en",
  button: {
    language: "en",
    getAppText: "Get the app",
    appStoreLink:
      "https://app.adjust.com/93rqtl6?redirect_android=https%3A%2F%2Fplay.google.com%2Fstor[…]tps%3A%2F%2Fitunes.apple.com%2Fde%2Fapp%2Ftier%2Fid1436140272",
    googlePlayLink:
      "https://app.adjust.com/93rqtl6?redirect_android=https%3A%2F%2Fplay.google.com%2Fstor[…]tps%3A%2F%2Fitunes.apple.com%2Fde%2Fapp%2Ftier%2Fid1436140272",
  },
  menuContent: [
    {
      language: "en",
      linkList: [
        {
          header: "Company",
          links: [
            {
              id: "200",
              linkAddress: [
                {
                  actionType: "internal",
                  address: "/how-tier-works",
                  openInNewWindow: false,
                  text: "How TIER Works",
                  icon: [],
                },
              ],
            },
            {
              id: "59",
              linkAddress: [
                {
                  actionType: "internal",
                  address: "/sustainability",
                  openInNewWindow: false,
                  text: "Sustainability",
                  icon: [],
                },
              ],
            },
            {
              id: "204",
              linkAddress: [
                {
                  actionType: "internal",
                  address: "/where-to-find-us",
                  openInNewWindow: false,
                  text: "Where to find us",
                  icon: [],
                },
              ],
            },
            {
              id: "208",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://about.tier.app/jobs/",
                  openInNewWindow: false,
                  text: "Career",
                  icon: [],
                },
              ],
            },
          ],
        },
        {
          header: "Products",
          links: [
            {
              id: "213",
              linkAddress: [
                {
                  actionType: "internal",
                  address: "/swapspot",
                  openInNewWindow: false,
                  text: "TIER Energy Network",
                  icon: [],
                },
              ],
            },
            {
              id: "217",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://mytier.app/",
                  openInNewWindow: true,
                  text: "myTIER",
                  icon: [],
                },
              ],
            },
          ],
        },
        {
          header: "Resources",
          links: [
            {
              id: "221",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://about.tier.app/press",
                  openInNewWindow: false,
                  text: "Press",
                  icon: [],
                },
              ],
            },
            {
              id: "225",
              linkAddress: [
                {
                  actionType: "internal",
                  address: "/blog",
                  openInNewWindow: false,
                  text: "Blog",
                  icon: [],
                },
              ],
            },
            {
              id: "229",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://tier.engineering/",
                  openInNewWindow: true,
                  text: "Engineering",
                  icon: [],
                },
              ],
            },
          ],
        },
        {
          header: "Support",
          links: [
            {
              id: "233",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://about.tier.app/help",
                  openInNewWindow: false,
                  text: "Help",
                  icon: [
                    {
                      url: "https://production.web.cms.tier.exu.dev/uploads/TIER-icn-v2_open-hand.svg",
                    },
                  ],
                },
              ],
            },
            {
              id: "237",
              linkAddress: [
                {
                  actionType: "internal",
                  address: "/contact",
                  openInNewWindow: false,
                  text: "Contact",
                  icon: [
                    {
                      url: "https://production.web.cms.tier.exu.dev/uploads/phone.svg",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          header: "Menu Footer",
          links: [
            {
              id: "241",
              linkAddress: [
                {
                  actionType: "internal",
                  address: "/modern-slavery-act",
                  openInNewWindow: false,
                  text: "Modern Slavery Act Statement",
                  icon: [],
                },
              ],
            },
            {
              id: "245",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://about.tier.app/impressum/",
                  openInNewWindow: false,
                  text: "Imprint",
                  icon: [],
                },
              ],
            },
            {
              id: "249",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://about.tier.app/privacy-notice/",
                  openInNewWindow: false,
                  text: "Privacy Notice",
                  icon: [],
                },
              ],
            },
            {
              id: "253",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://about.tier.app/terms-and-conditions/",
                  openInNewWindow: false,
                  text: "Terms & Conditions",
                  icon: [],
                },
              ],
            },
          ],
        },
        {
          header: "Social Bar",
          links: [
            {
              id: "257",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://www.facebook.com/tier.emobility",
                  openInNewWindow: false,
                  text: "Facebook",
                  icon: [],
                },
              ],
            },
            {
              id: "261",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://www.instagram.com/tier.mobility",
                  openInNewWindow: true,
                  text: "Instagram",
                  icon: [],
                },
              ],
            },
            {
              id: "270",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://twitter.com/tier_mobility",
                  openInNewWindow: true,
                  text: "Twitter",
                  icon: [],
                },
              ],
            },
            {
              id: "274",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://www.linkedin.com/company/tiermobility/",
                  openInNewWindow: true,
                  text: "Follow us on LinkedIn",
                  icon: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  footerContent: [
    {
      language: "en",
      linkList: [
        {
          header: "Company",
          links: [
            {
              id: "200",
              linkAddress: [
                {
                  actionType: "internal",
                  address: "/how-tier-works",
                  openInNewWindow: false,
                  text: "How TIER Works",
                },
              ],
            },
            {
              id: "59",
              linkAddress: [
                {
                  actionType: "internal",
                  address: "/sustainability",
                  openInNewWindow: false,
                  text: "Sustainability",
                },
              ],
            },
            {
              id: "204",
              linkAddress: [
                {
                  actionType: "internal",
                  address: "/where-to-find-us",
                  openInNewWindow: false,
                  text: "Where to find us",
                },
              ],
            },
            {
              id: "208",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://about.tier.app/jobs/",
                  openInNewWindow: false,
                  text: "Career",
                },
              ],
            },
          ],
        },
        {
          header: "Products",
          links: [
            {
              id: "213",
              linkAddress: [
                {
                  actionType: "internal",
                  address: "/swapspot",
                  openInNewWindow: false,
                  text: "TIER Energy Network",
                },
              ],
            },
            {
              id: "217",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://mytier.app/",
                  openInNewWindow: true,
                  text: "myTIER",
                },
              ],
            },
          ],
        },
        {
          header: "Resources",
          links: [
            {
              id: "221",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://about.tier.app/press",
                  openInNewWindow: false,
                  text: "Press",
                },
              ],
            },
            {
              id: "225",
              linkAddress: [
                {
                  actionType: "internal",
                  address: "/blog",
                  openInNewWindow: false,
                  text: "Blog",
                },
              ],
            },
            {
              id: "229",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://tier.engineering/",
                  openInNewWindow: true,
                  text: "Engineering",
                },
              ],
            },
          ],
        },
        {
          header: "Support",
          links: [
            {
              id: "233",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://about.tier.app/help",
                  openInNewWindow: false,
                  text: "Help",
                },
              ],
            },
            {
              id: "237",
              linkAddress: [
                {
                  actionType: "internal",
                  address: "/contact",
                  openInNewWindow: false,
                  text: "Contact",
                },
              ],
            },
          ],
        },
        {
          header: "Bottom Links",
          links: [
            {
              id: "241",
              linkAddress: [
                {
                  actionType: "internal",
                  address: "/modern-slavery-act",
                  openInNewWindow: false,
                  text: "Modern Slavery Act Statement",
                },
              ],
            },
            {
              id: "245",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://about.tier.app/impressum/",
                  openInNewWindow: false,
                  text: "Imprint",
                },
              ],
            },
            {
              id: "249",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://about.tier.app/privacy-notice/",
                  openInNewWindow: false,
                  text: "Privacy Notice",
                },
              ],
            },
            {
              id: "253",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://about.tier.app/terms-and-conditions/",
                  openInNewWindow: false,
                  text: "Terms & Conditions",
                },
              ],
            },
          ],
        },
        {
          header: "Social",
          links: [
            {
              id: "257",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://www.facebook.com/tier.emobility",
                  openInNewWindow: false,
                  text: "Facebook",
                },
              ],
            },
            {
              id: "261",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://www.instagram.com/tier.mobility",
                  openInNewWindow: true,
                  text: "Instagram",
                },
              ],
            },
            {
              id: "270",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://twitter.com/tier_mobility",
                  openInNewWindow: true,
                  text: "Twitter",
                },
              ],
            },
            {
              id: "274",
              linkAddress: [
                {
                  actionType: "external",
                  address: "https://www.linkedin.com/company/tiermobility/",
                  openInNewWindow: true,
                  text: "Follow us on LinkedIn",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  languages: [
    {
      languageName: "Arabic",
      code: "ar",
    },
    {
      languageName: "Catalan",
      code: "ca",
    },
    {
      languageName: "Croatian",
      code: "hr",
    },
    {
      languageName: "Danish",
      code: "da",
    },
    {
      languageName: "Dutch",
      code: "nl",
    },
    {
      languageName: "English",
      code: "en",
    },
    {
      languageName: "Finnish",
      code: "fi",
    },
    {
      languageName: "French",
      code: "fr",
    },
    {
      languageName: "German",
      code: "de",
    },
    {
      languageName: "Greek",
      code: "el",
    },
    {
      languageName: "Hebrew",
      code: "he",
    },
    {
      languageName: "Hungarian",
      code: "hu",
    },
    {
      languageName: "Italian",
      code: "it",
    },
    {
      languageName: "Norwegian",
      code: "no",
    },
    {
      languageName: "Polish",
      code: "pl",
    },
    {
      languageName: "Portuguese",
      code: "pt",
    },
    {
      languageName: "Romanian",
      code: "ro",
    },
    {
      languageName: "Slovak",
      code: "sk",
    },
    {
      languageName: "Spanish",
      code: "es",
    },
    {
      languageName: "Swedish",
      code: "sv",
    },
  ],
}
