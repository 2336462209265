// Dependencies
import React, { useEffect, useState } from "react"
import { Pivot as Hamburger } from "hamburger-react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

// Components
import PalButton from "../../components/buttons/PalButton"
import Container from "../../components/containers/Container"
import Logo from "../../components/icons/Logo"

// Hooks & Helpers
import { setEventListener } from "../../utils/helpers"
import { useIsMacOS } from "../../components/hooks/useIsMacOS"
import LanguageSwitcher from "../../components/LanguageSwitcher"
import ShareCopyIcon from "../../components/icons/ShareCopyIcon"
import CloseIcon from "../../components/icons/CloseIcon"

const NavBar = ({ toggleMenu, isOpen, button, languages }) => {
  // States & Refs
  const { pathname, href, ...location } = useLocation()
  const [theme, setTheme] = useState("light")
  const [openCopy, setOpenCopy] = useState(false)
  const [scrolled, setScrolled] = useState(false)
  const [, setForceLogoColor] = useState(null)

  const { getAppText, appStoreLink, googlePlayLink } = button

  let isAppleDevice = useIsMacOS()
  let appUrl = isAppleDevice ? appStoreLink : googlePlayLink
  let originalTheme = ""

  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 88) {
      setScrolled(true)
      if (originalTheme === "light") setTheme("dark")
    } else {
      setScrolled(false)
      if (originalTheme === "light") setTheme("light")
    }
  }

  const shareUrl = () => {
    setOpenCopy(!openCopy)
  }

  useEffect(() => {
    // Runs only once
    setEventListener("nav-scheme", e => {
      setTheme(e.detail)
      originalTheme = e.detail
    })
    setEventListener("force-logo", e => setForceLogoColor(e.detail))
    window.addEventListener("scroll", handleScroll)

    return () => {
      setEventListener("nav-scheme", e => setTheme(e.detail), true)
      setEventListener("force-logo", e => setForceLogoColor(e.detail), true)
    }
  }, [])

  return (
    <Container
      skipMargin
      fullWidth
      type="wide"
      className={
        "flex items-center h-16 md:h-18 fixed top-0 left-0 z-50 w-screen transition " +
        (scrolled && !isOpen ? "bg-background shadow-nav" : "bg-transparent shadow-none")
      }
    >
      <div className="flex flex-1 justify-start" onClick={() => toggleMenu(false)}>
        <Link to="/">
          <Logo color={theme === "dark" || isOpen ? "tertiary" : "primary"} className="h-8 w-28 md:h-auto md:w-auto" />
        </Link>
      </div>
      <div className="flex flex-1 items-center justify-end gap-x-4 -mr-2">
        {pathname && !pathname.includes("/preview/") ? (
          <LanguageSwitcher
            className={isOpen ? "block " : "hidden sm:block transition cursor-pointer "}
            theme={isOpen ? "dark" : theme}
            toggleMenu={() => toggleMenu(false)}
            languages={languages}
          />
        ) : (
          <div className={"relative"}>
            <button
              className={"relative w-12 h-12 rounded-lg border flex items-center justify-center bg-green border-green cursor-pointer"}
              onClick={() => shareUrl()}
            >
              <span className={"mb-1 text-white"}>
                {openCopy ? <CloseIcon color={"white"} width={30} height={30} /> : <ShareCopyIcon className="text-white" />}
              </span>
            </button>
            <div
              className={
                "absolute w-auto h-auto bg-green text-white rounded-lg px-6 py-2 z-40 shadow-nav overflow-y-auto top-16 right-0 " +
                (openCopy ? "block" : "hidden")
              }
            >
              <p>{href}</p>
            </div>
          </div>
        )}
        <a href={appUrl} className="hidden lg:flex lg:mr-4">
          <PalButton isDownloadButton={true} label={getAppText} color={theme === "dark" || isOpen ? "primary" : "secondary"} />
        </a>
        <Hamburger size={28} color={theme === "dark" || isOpen ? "#0E1A50" : "#fff"} toggled={isOpen} toggle={toggleMenu} />
      </div>
    </Container>
  )
}

NavBar.propTypes = {
  button: PropTypes.object,
  toggleMenu: PropTypes.func,
  isOpen: PropTypes.bool,
}

NavBar.defaultProps = {
  scheme: "light",
}

export default NavBar
