import React from "react"
import PropTypes from "prop-types"

function PlusIcon({ width, height, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        className="stroke-current"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M3.516 12h16.97M12 20.485V3.515"
      ></path>
    </svg>
  );
}

PlusIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

PlusIcon.defaultProps = {
  width: 24,
  height: 24,
}

export default PlusIcon;
