import { useEffect } from "react"

export const useClickOutside = (ref, callback, active) => {
  const handleClick = e => {
    if (!active) return
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener("click", handleClick)
    return () => {
      document.removeEventListener("click", handleClick)
    }
  })
}
