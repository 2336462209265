import React from "react"
import PropTypes from "prop-types"

function DownloadIcon({ color, hovered }) {
  const colorPick = name => {
    switch (name) {
      case "primary":
        return hovered ? "#FFFAF6" : "#0E1A50"
      case "secondary":
        return hovered ? "#0E1A50" : "#FFFAF6"
      default:
        return hovered ? "#FFFAF6" : "#0E1A50"
    }
  }

  const arrowColorPick = name => {
    switch (name) {
      case "primary":
        return hovered ? "#C8EAE8" : "#067A72"
      case "secondary":
        return hovered ? "#067A72" : "#C8EAE8"
      default:
        return hovered ? "#C8EAE8" : "#067A72"
    }
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none" viewBox="0 0 36 36">
      <path
        stroke={colorPick(color)}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M21.375 4.5h-6.75V6c0 .825.675 1.5 1.5 1.5h3.75c.825 0 1.5-.675 1.5-1.5V4.5z"
      ></path>
      <path
        stroke={colorPick(color)}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M22.5 31.5h-9c-1.65 0-3-1.35-3-3v-21c0-1.65 1.35-3 3-3h9c1.65 0 3 1.35 3 3v21c0 1.65-1.35 3-3 3z"
      ></path>
      <path
        stroke={arrowColorPick(color)}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M18 15.57v7.905M21 20.475l-3 3-3-3"
      ></path>
    </svg>
  )
}

DownloadIcon.propTypes = {
  color: PropTypes.string,
  hovered: PropTypes.bool,
}

DownloadIcon.defaultProps = {
  color: "primary",
  hovered: false,
}

export default DownloadIcon
