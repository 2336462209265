import React from "react"
import PropTypes from "prop-types"

function CloseIcon({ color, width, height, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 17 16">
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M4.5 4l8 8M4.5 12l8-8"></path>
    </svg>
  )
}

CloseIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

CloseIcon.defaultProps = {
  color: "#067A72",
  width: 17,
  height: 16,
}

export default CloseIcon
