import React from "react"
import PropTypes from "prop-types"

function ShareCopyIcon({ color, width, height, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 24 24`} {...props}>
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path
        fill={color}
        d="M4 19h16v-5h2v6a1 1 0 01-1 1H3a1 1 0 01-1-1v-6h2v5zM16.172 7l-3.95-3.95 1.414-1.414L20 8l-6.364 6.364-1.414-1.414L16.172 9H5V7h11.172z"
      ></path>
    </svg>
  )
}

ShareCopyIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

ShareCopyIcon.defaultProps = {
  color: "white",
  width: 24,
  height: 24,
}

export default ShareCopyIcon
