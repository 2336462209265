import React, { useEffect, useState, useCallback, useRef } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import gsap from "gsap"
import { Link } from "gatsby"
import { goTo, isHTML } from "../../utils/helpers"

import CustomEase from "../../utils/CustomEase"

gsap.registerPlugin(CustomEase)

// Components
import PlusIcon from "../../components/icons/PlusIcon"
import ChevronDown from "../../components/icons/ChevronDown"
import PalButton from "../../components/buttons/PalButton"
import { useWindowSize } from "../../components/hooks/useWindowSize"

const ItemWrapper = styled.div`
  & > p {
    margin-bottom: 40px;
  }

  & a {
    color: #0050ff;
  }
`

const AccordionJob = ({ header, jobs, selectedItem, index, link, toggleContent, total, ...props }) => {
  const { className, ...addonProps } = props
  const { width } = useWindowSize()
  const [active, setActive] = useState(false)
  const contentRef = useRef()
  const headerRef = useRef()

  /**
   * Methods
   */
  const toggleItem = () => {
    const newValue = selectedItem === index ? null : index
    setActive(newValue === index)
    toggleContent(newValue)
  }

  const onKeyUpAction = e => {
    if (e.key === "Enter" || e.key === "Space") toggleItem()
  }
  const onKeyDownAction = e => {
    if (e.key === "Escape") {
      toggleContent(null)
      setActive(false)
    }
  }

  const animate = useCallback(() => {
    const height = active ? "auto" : 0
    const opacity = active ? 1 : 0
    CustomEase.create("accordion", "0.45,0.05,0.55,0.95")
    gsap.to(contentRef.current, { height, opacity, duration: 0.3, ease: "accordion" })
  }, [active, contentRef])

  /** Run on component mount */
  useEffect(() => {
    if (active) animate()
  }, [])

  /** Run whenever the item is updated */
  useEffect(() => {
    animate()
  }, [active, animate])

  /** Close item if other accordeon item was selected */
  useEffect(() => {
    if (active && selectedItem !== index) setActive(false)
  }, [selectedItem])

  /**
   * Component
   */
  return (
    <li className={"border-b border-gray-200 w-full " + className} {...addonProps}>
      <div
        ref={headerRef}
        className="cursor-pointer flex items-center justify-start py-8 lg:py-10 group space-x-4 focus:outline-none"
        role="button"
        tabIndex="0"
        aria-haspopup="listbox"
        aria-expanded={active ? "true" : "false"}
        onClick={toggleItem}
        onKeyUp={onKeyUpAction}
        onKeyDown={onKeyDownAction}
      >
        <ChevronDown color="#0E1A50" className={(active ? "rotate-180" : "rotate-0") + " transform transition-transform mt-2"} />
        <h6 className="font-extended font-extrabold text-blue">{header}</h6>
        <div className="bg-orange-highlight text-blue text-n3 py-0.5 px-3 h-6 rounded-full mt-2">{total}</div>
      </div>
      <div ref={contentRef} role="listbox" className="w-full h-0 opacity-0 overflow-hidden text-blue space-y-12 md:pl-10">
        {jobs &&
          jobs.length > 0 &&
          jobs.map(job => {
            return (
              <div
                className="w-full flex flex-col md:flex-row items-start md:h-20 border-b border-gray-200 last-of-type:border-none space-y-3 md:space-y-0 pb-4 md:pb-0"
                onClick={width <= 768 ? () => goTo(job.absolute_url, "external", true) : null}
              >
                <div className="flex-1">
                  <p className="font-bold" key={job.title}>
                    {job.title}
                  </p>
                </div>
                <div className="flex-1 flex justify-center">
                  <span className="bg-white pt-0.5 pb-1 px-3 rounded-full text-p3 opacity-0">
                    {job.departments ? job.departments[0]?.name : job.brand}
                  </span>
                </div>
                <div className="flex-1 flex justify-start md:pl-20">
                  <p className="text-n3 text-gray-500">{job.location.name}</p>
                </div>
                <div className="flex-1 hidden md:flex justify-end">
                  <a target="__blank" href={job.absolute_url || job.apply_url}>
                    <PalButton label="Apply Now" />
                  </a>
                </div>
              </div>
            )
          })}
      </div>
      {/* {isHTML(body) ? (
        <ItemWrapper
          ref={contentRef}
          className="h-0 opacity-0 overflow-hidden ext-p2 text-blue text-justify max-w-2xl"
          role="listbox"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      ) : (
        <div ref={contentRef} className="h-0 opacity-0 overflow-hidden" role="listbox">
          <p className="text-p2 text-blue text-justify mb-10 max-w-2xl">{body}</p>
          {link && (
            <Link to={link.link}>
              <p className="text-p2 text-blue-informative underline text-justify mb-10 max-w-2xl">{link.name}</p>
            </Link>
          )}
        </div>
      )} */}
    </li>
  )
}

AccordionJob.propTypes = {
  /**
   * Item header's text
   */
  header: PropTypes.string.isRequired,
  /**
   * Item icon
   */
  selectedItem: PropTypes.number,
  /**
   * This item's index number inside the accordeon
   */
  index: PropTypes.number.isRequired,
  /**
   * Function to update the accordeon state
   */
  toggleContent: PropTypes.func.isRequired,
  link: PropTypes.object,
}

AccordionJob.defaultProps = {
  selectedItem: null,
}

export default AccordionJob
