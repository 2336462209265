import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"

const Card = styled.div`
  ${({ $large }) =>
    $large &&
    css`
      width: 336px;
      height: 208px;

      @media (max-width: 1280px) {
        width: 100%;
        max-width: 300px;
        height: 155px;
      }
    `}

  ${({ $large }) =>
    !$large &&
    css`
      @media (min-width: 768px) {
        width: 212px;
        min-height: 62px;
      }
    `}
`

const KPICard = ({ title, subline, background, backgroundImage, large, forwardedRef }) => {
  const bg = "bg-" + background

  return (
    <Card
      className={
        "relative overflow-hidden flex flex-col flex-shrink-0 items-center justify-center rounded-xl " + (background ? bg : "md:bg-blue-background")
      }
      $large={large}
      ref={ref => (forwardedRef ? forwardedRef.push(ref) : null)}
    >
      {backgroundImage && (
        <img
          alt="kpi-background"
          src={backgroundImage}
          className="absolute z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover object-top h-full w-full pointer-events-none"
        />
      )}
      <p
        className={
          "relative z-10  text-center md:text-blue md:font-bold md:px-4 whitespace-pre-line " +
          (large ? "text-h4 font-bold mb-4 text-blue" : "text-p2 text-gray-500")
        }
      >
        {title}
      </p>
      {large && subline && <p className="relative z-0 text-base text-gray-400 text-center">{subline}</p>}
    </Card>
  )
}

KPICard.propTypes = {
  title: PropTypes.string.isRequired,
  subline: PropTypes.string,
  background: PropTypes.string,
  backgroundImage: PropTypes.string,
  forwardedRef: PropTypes.array,
  large: PropTypes.bool,
}

export default KPICard
